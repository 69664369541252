import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/kuma-fitness-story-featured-image.png";
import gym from "../../assets/image/kuma-fitness/kuma-fitness.png";
import messageBoard from "../../assets/image/kuma-fitness/message-board.png";
import recurringShifts from "../../assets/image/kuma-fitness/recurring-shifts.png";
import openShifts from "../../assets/image/kuma-fitness/open-shifts.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Customer story: How Kuma Fitness uses Camelo to schedule and stay connected across multiple locations"
          description="Find out how the Camelo employee scheduling app transforms shift scheduling, 
          timesheets, & communication for Kuma Fitness, a body-neutral kickboxing and 
          fitness women's gym that has been empowering women since 2019. 
          Discover why Kuma Fitness switched to Camelo, a ZoomShift alternative that works as 
          both a scheduling app and team communication tool"
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Kuma Fitness uses Camelo to schedule and stay connected across multiple locations
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-28 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://kumafitness.com/" target="__blank">
                      {`Kuma Fitness`}
                    </Link>
                    {' '} is a body-neutral kickboxing and fitness women's gym that has been empowering women
                    since 2019. With two locations serving approximately 150 members and offering over
                    45 classes weekly, owner Stacy Kim has created a unique business model that trains members
                    to become coaches—building a community that lifts each other up.
                  </p>
                  <p className="gr-text-8 text-black">
                    Scheduling might not have been a big problem for Kuma Fitness in the beginning
                    but the need to keep everything—schedules, chats, and team communication—in one place
                    quickly became a priority. When searching for a more overall solution,
                    Stacy found Camelo and knew it was the ideal one for her team.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Creating a welcoming space for women in fitness
                  </h4>
                  <p className="gr-text-8 text-black">
                    Kuma Fitness was born from a vision to create a comfortable,
                    welcoming space for women to move their bodies and build strength.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “We have helped bring women back to the gym by creating a welcoming and comfortable
                      space for them to move.”
                    </p>
                  </blockquote>
                  <img
                    className="w-100 my-6"
                    src={gym}
                    alt="women’s gym at Kuma Fitness"
                  />
                  <p className="text-center font-italic">
                    Specialized gym practices for women at Kuma Fitness
                  </p>
                  <p className="gr-text-8 text-black">
                    "We prioritize strength and confidence and empower our members in each class."
                    Stacy shared. This philosophy extends beyond just their training approach—it's
                    blended into their entire business model, creating opportunities for members to
                    grow from participants to leaders.
                  </p>

                  <h4 className="pt-8 pb-4">
                    A unique coaching model built on community
                  </h4>
                  <p className="gr-text-8 text-black">
                    What sets Kuma Fitness apart is their distinctive approach to staffing.
                    Rather than hiring trainers from outside, they've developed a system that allows members
                    to become part of the teaching team.
                  </p>
                  <p className="gr-text-8 text-black">
                    "We have a unique model for our gym. We train our members to become coaches through
                    a 6-week instructor training program I designed," Stacy shared. "We have
                    2 full-time employees and the rest are part-time coaching just a few classes each week.
                    They have other full-time jobs, and they coach because they love giving back
                    to our community and helping other women feel powerful and strong."
                  </p>
                  <p className="gr-text-8 text-black">
                    This community-centered approach extends to their assistant program as well.
                    With two levels—Studio Assistants who help with setup and cleaning and
                    Coaching Assistants who receive the same training as coaches
                    except for leading classes—Kuma Fitness has created a tiered system that allows members
                    to participate at their comfort level.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Managing multiple locations and schedules
                  </h4>
                  <p className="gr-text-8 text-black">
                    With two locations, over 45 weekly classes, and a staffing model that includes
                    both coaches and assistants at different levels, Kuma Fitness faces
                    unique scheduling challenges.
                  </p>
                  <p className="gr-text-8 text-black">
                    "We manage 4 schedules: one for coaches and one for assistants in each location,"
                    Stacy said. Each week, their assistants would pick up open shifts (classes)
                    when they are able to help. This complexity requires a scheduling solution
                    that can handle multiple overlapping schedules while remaining easy to navigate.
                  </p>
                  <p className="gr-text-8 text-black">
                    Although their previous scheduling tool, ZoomShift, was working adequately,
                    they were using Slack separately for team communication, requiring team members to
                    switch between two different apps.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Discovering Camelo: putting all tools into one platform
                  </h4>
                  <p className="gr-text-8 text-black">
                    After discovering Camelo, Stacy saw an opportunity to carry out
                    their operations more smoothly by bringing scheduling and communication
                    into a single platform.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Having one app for communication and scheduling has been great.
                      When we onboard new team members, we just need to train them on a single app.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    This combination of many features in one app has been especially valuable for
                    their remote management style. Despite having physical locations, the Kuma Fitness team
                    isn't all in the same space at the same time, making efficient communication
                    significantly important.
                  </p>

                  <h4 className="pt-8 pb-4">Message boards: a game-changer for team communication</h4>
                  <p className="gr-text-8 text-black">
                    One feature that has been particularly valuable for Kuma Fitness is Camelo's {' '}
                    <Link
                      to="https://camelohq.com/features/workplace-communication/"
                      target="__blank"
                    >
                      {`message board`}
                    </Link>.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “We love the message board feature because it allows our team to announce
                      important messages and see who has not acknowledged them. Making sure
                      announcements get to people on time is really important for us.”
                    </p>
                  </blockquote>
                  <img
                    className="w-100"
                    src={messageBoard}
                    alt="message boards on Camelo team communication app"
                  />
                  <p className="text-center font-italic">
                    Message boards on Camelo web app and mobile app
                  </p>
                  <p className="gr-text-8 text-black">
                    This visibility has eliminated the uncertainty around whether team members
                    have seen critical information—a common challenge in remote team management.
                  </p>

                  <h4 className="pt-8 pb-4">Team favorite: scheduling with recurring shifts</h4>
                  <p className="gr-text-8 text-black">
                    For Kuma Fitness, "shifts" are actually classes, and their scheduling needs
                    differ from typical business operations. Camelo's flexibility has allowed them to
                    adapt the app to their specific requirements.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Scheduling on the desktop is amazing. One thing I LOVE that we didn't have before
                      is the ability to schedule recurring shifts. This means we don't need to copy
                      from the previous week or make templates, we can just set each shift as a recurring one
                      and publish one month at a time.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    This feature has significantly reduced the time Stacy spends on administrative tasks,
                    allowing her to focus more on growing the business and supporting her community.
                  </p>
                  <img
                    className="w-100"
                    src={recurringShifts}
                    alt="recurring shifts on Camelo employee scheduling app"
                  />
                  <p className="text-center font-italic">
                    Create recurring shifts with <b>Repeat</b> and <b>End on</b> options on Camelo
                  </p>

                  <h4 className="pt-8 pb-4">
                    The comprehensive solution Kuma Fitness needed
                  </h4>
                  <p className="gr-text-8 text-black">
                    Kuma Fitness now relies on multiple Camelo features to keep their operation running smoothly.
                    From{' '}
                    <Link
                      to="https://camelohq.com/features/scheduling-software/"
                      target="__blank"
                    >
                      {`scheduling tools`}
                    </Link> and timesheets to shift swaps and open shifts, the app has become integral
                    to their daily operations.
                  </p>
                  <p className="gr-text-8 text-black">
                    The open shifts feature has been particularly useful for their unique staffing model,
                    allowing Coaching Assistants to pick up classes when they're available to help—providing
                    flexibility for both the business and the team members.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Camelo has made this VERY easy to navigate and manage.”
                    </p>
                  </blockquote>
                  <img
                    className="w-100"
                    src={openShifts}
                    alt="open shifts on Camelo free schedule app"
                  />
                  <p className="text-center font-italic">
                    Create open shifts on Camelo
                  </p>

                  <h4 className="pt-8 pb-4">
                    The overall impact: time saved and better communication
                  </h4>
                  <p className="gr-text-8 text-black">
                    For Kuma Fitness, Camelo has simplified operations and improved team communication.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Camelo has saved me time and helped our business communicate more efficiently.
                      It's been really nice having one place to manage our team.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    While Stacy noted that the chat feature still lacks some functionality they miss
                    from Slack—such as tagging members and editing messages—she emphasized that
                    "the benefits outweigh this."
                  </p>

                  <h4 className="pt-8 pb-4">
                    A more suitable solution that stands above other options
                  </h4>
                  <p className="gr-text-8 text-black">
                    Having used several other products, Stacy is particularly impressed with
                    Camelo's user interface and the team behind the product.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I've used many of the competitors (ZoomShift, WhenIWork, Homebase), and this app is
                      so much better! It's well-built and simple to use.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Finding Camelo as a ZoomShift alternative—and a strong replacement for WhenIWork
                    and Homebase—has created a positive experience that turned Stacy into
                    an advocate for our app. When asked if she would recommend Camelo to others,
                    her response is enthusiastic.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Absolutely. I've recommended it to my gym community already.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Growing together with shared values
                  </h4>
                  <p className="gr-text-8 text-black">
                    For Kuma Fitness, Camelo isn't just a scheduling tool—it's a partner that
                    aligns with their values of empowerment and community. As Kuma Fitness continues to
                    strengthen women through fitness, Camelo strengthens their operations by
                    providing the tools they need to manage their unique business model efficiently.
                    Now they can focus on what’s important—creating a supportive environment where women
                    can discover their strength, both physically and mentally.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
